import request from '@/utils/request'

/**
 * @desc 用户信息接口
 */
export function userinfo() {
  return request({
    url: '/api/admin/me',
    method: 'get'
  })
}

/**
 * @desc 上传接口
 */
export function directUpload(data: any) {
  return request({
    url: '/api/admin/direct_uploads',
    method: 'POST',
    data
  })
}

// 上传第二步
export function directUploadTwo(url: string, data: any, config: any) {
  return request.put(url, data, config)
}

/**
 * @desc bundle数据接口
 */
export function getBundle() {
  return request({
    url: '/api/admin/system_configs/app_package_names',
    method: 'get'
  })
}

// chufa
