import { Menu, MenuProps, Spin } from 'antd'
import { createElement, useEffect, useState } from 'react'
import useAccess from '@/hooks/useAccess'
import { useLocation, useNavigate } from 'react-router-dom'
import menu, { rootSubmenuKeys } from './menu'
import { RouteObject } from '@/router/router-config'
import CollapseIcon from '../Header/components/CollapseIcon'
import * as Icons from '@ant-design/icons'

type MenuItem = Required<MenuProps>['items'][number]

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: 'group'
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type
  } as MenuItem
}

const App: React.FC = () => {
  const access = useAccess()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [menuList, setMenuList] = useState<MenuItem[]>([])
  const { pathname } = useLocation()
  const [selectedKeys, setSelectedKeys] = useState<string[]>([pathname])

  // 动态渲染 Icon 图标
  const customIcons: { [key: string]: any } = Icons
  const addIcon = (name: string) => {
    return name ? createElement(customIcons[name]) : null
  }

  // 处理后台返回菜单 key 值为 antd 菜单需要的 key 值
  const deepLoopFloat = (MenuArr: RouteObject[], newArr: MenuItem[] = []) => {
    MenuArr.forEach((item) => {
      if (!item?.children?.length) {
        if (access?.includes('all') ? true : item.meta?.access ? access?.includes(item.meta?.access as string) : true) {
          newArr.push(getItem(item?.meta?.title, item.path || '/', addIcon(item.icon as string)))
        }
      } else {
        // 多级情况， item.meta?.access为数组
        const has = (item.meta?.access as string[])?.some((v) => access?.includes(v))

        if (access?.includes('all') || has) {
          newArr.push(
            getItem(item?.meta?.title, item.path || '/', addIcon(item.icon as string), deepLoopFloat(item.children))
          )
        }
      }
    })

    return newArr
  }

  const getMenuData = async () => {
    setLoading(true)
    try {
      setMenuList(deepLoopFloat(menu))
    } finally {
      setLoading(false)
    }
  }

  // 点击当前菜单跳转页面
  const clickMenu: MenuProps['onClick'] = ({ key }) => {
    navigate(key)
  }

  const [openKeys, setOpenKeys] = useState<string[]>([])

  const onOpenChange: MenuProps['onOpenChange'] = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1)
    if (latestOpenKey && rootSubmenuKeys.indexOf(latestOpenKey!) === -1) {
      setOpenKeys(keys)
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : [])
    }
  }

  const handlePathChange = () => {
    const menuItem = menu.find((item) => {
      if (item.path == pathname) {
        return item
      } else if (item.children) {
        return item.children.find((child) => child.path === pathname)
      }
    })

    setOpenKeys([(menuItem?.path as string) || 'index'])
    setSelectedKeys([pathname])
  }

  // 刷新页面菜单保持高亮
  useEffect(() => {
    handlePathChange()
  }, [pathname])

  useEffect(() => {
    getMenuData()
  }, [])

  return (
    <div className='menu'>
      <Spin spinning={loading} tip='Loading...'>
        <Menu
          mode='inline'
          triggerSubMenuAction='hover'
          selectedKeys={selectedKeys}
          items={menuList}
          onClick={clickMenu}
          openKeys={openKeys}
          onOpenChange={onOpenChange}
          style={{ overflowY: 'auto', height: 'calc(100vh - 96px)', backgroundColor: '#f1f3fa' }}
        />
        <CollapseIcon />
      </Spin>
    </div>
  )
}

export default App
