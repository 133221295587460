import { Layout } from 'antd'
import AvatarIcon from './components/AvatarIcon'
import Logo from '../Menu/components/Logo'
import './index.less'

const LayoutHeader = () => {
  const { Header } = Layout

  return (
    <Header>
      <div className='header-lf'>
        <Logo></Logo>
      </div>
      <div className='header-ri'>
        <AvatarIcon />
      </div>
    </Header>
  )
}

export default LayoutHeader
