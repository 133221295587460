import request from '@/utils/request'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Props = any

/**
 * @desc 登出
 */
export function signOut() {
  return request({
    url: '/api/admin/sign_out',
    method: 'DELETE'
  })
}
