import { Context, Dispatch, SetStateAction, createContext, useEffect, useState } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { Layout } from 'antd'

import LayoutMenu from './components/Menu'
import LayoutHeader from './components/Header'
import { searchRoute } from '@/utils/auth'
import routerConfig from '@/router/router-config'
import './index.less'
import { getBundle, userinfo } from './api'
import Preview from './components/Preview'
import * as Sentry from '@sentry/react'

export interface GlobalContextProps {
  isCollapse?: boolean
  setIsCollapse?: Dispatch<SetStateAction<boolean>>
  access?: string[]
  useInfo?: {
    email?: string
    super_admin?: boolean
    permissions?: string[]
    name?: string
    remake?: string
  }
  bundleOption?: BundleOptionItem[]
}

interface BundleOptionItem {
  app_name: string
  package_name: string
  os_type: 'android' | 'ios'
}

export const globalContext: Context<GlobalContextProps> = createContext({})

const LayoutIndex = () => {
  const { Sider, Content } = Layout
  const [isCollapse, setIsCollapse] = useState(false)
  const [access, setAccess] = useState<string[]>([])
  const [loading, setLoading] = useState(true)
  const [useInfo, setUserInfo] = useState({})
  const [bundleOption, setBundleOption] = useState<BundleOptionItem[]>([])

  const { pathname } = useLocation()
  const route = searchRoute(pathname, routerConfig)
  const navigate = useNavigate()

  // 监听窗口大小变化
  const listeningWindow = () => {
    window.onresize = () => {
      return (() => {
        const screenWidth = document.body.clientWidth
        if (!isCollapse && screenWidth < 1200) setIsCollapse(true)
        if (!isCollapse && screenWidth > 1200) setIsCollapse(false)
      })()
    }
  }

  const getUserInfo = async () => {
    const res: any = await userinfo()
    if (res.code === 0) {
      const accessArr = res.data.permissions as string[]
      const isSuperAdmin = res.data.super_admin

      if (isSuperAdmin) {
        accessArr.push('administrator')
        accessArr.unshift('all')
      }

      setAccess(accessArr)
      setUserInfo(res.data)
      console.log('res.data', res.data)
      Sentry.setUser({
        email: res.data.email,
        username: res.data.name
      })
      const routeAccess = route?.meta?.access
      if (!accessArr.includes('all') && routeAccess && !accessArr.includes(routeAccess as string)) {
        navigate('/404')
      }
      setLoading(false)
    } else {
      navigate('/login')
    }
  }

  const handleBundle = async () => {
    const res = await getBundle()
    setBundleOption(res.data)
  }

  useEffect(() => {
    listeningWindow()
    getUserInfo()
  }, [pathname])

  useEffect(() => {
    !loading && handleBundle()
  }, [!loading])

  return (
    // 这里不用 Layout 组件原因是切换页面时样式会先错乱然后在正常显示，造成页面闪屏效果
    <section className='container'>
      <globalContext.Provider
        value={{
          isCollapse,
          access,
          setIsCollapse,
          useInfo,
          bundleOption
        }}
      >
        {!loading && (
          <Layout>
            <LayoutHeader></LayoutHeader>
            <Layout hasSider>
              <Sider trigger={null} collapsed={isCollapse} theme='dark'>
                <LayoutMenu></LayoutMenu>
              </Sider>
              <Content>
                <Outlet></Outlet>
              </Content>
            </Layout>
          </Layout>
        )}
      </globalContext.Provider>

      {/* <Preview useInfo={useInfo} /> */}
    </section>
  )
}

export default LayoutIndex
