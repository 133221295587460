import { globalContext, GlobalContextProps } from '@/layout'
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons'
import { useContext } from 'react'

interface Props {
  onChange?: () => void
}

const CollapseIcon = (props: Props) => {
  const { isCollapse, setIsCollapse }: GlobalContextProps = useContext(globalContext)
  return (
    <div
      className='collapsed'
      onClick={() => {
        props.onChange && props.onChange()
        setIsCollapse && setIsCollapse(!isCollapse)
      }}
    >
      {isCollapse ? (
        <MenuUnfoldOutlined id='isCollapse' rev={undefined} />
      ) : (
        <span style={{ display: 'inline-flex' }}>
          <MenuFoldOutlined id='isCollapse' rev={undefined} style={{ color: '#1677ff' }} />
          <span className='collapsed-txt'>收起侧边栏</span>
        </span>
      )}
    </div>
  )
}

export default CollapseIcon
