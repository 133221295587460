import { Avatar, Modal, Dropdown, message, MenuProps } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'
import avatar from '@/assets/images/avatar.png'
import { removeToken } from '@/utils/auth'
import { signOut } from './api'
import { GlobalContextProps, globalContext } from '@/layout'
import { useContext } from 'react'
import useAccess from '@/hooks/useAccess'

const AvatarIcon = () => {
  const navigate = useNavigate()
  const access = useAccess()
  const { useInfo }: GlobalContextProps = useContext(globalContext)

  const handleSignOut = async () => {
    const res: any = await signOut()

    if (res.code === 0) {
      message.success('退出登录成功！')
      removeToken()
      navigate('/login')
    } else {
      message.error(res.message)
    }
  }

  // 退出登录
  const logout = () => {
    Modal.confirm({
      title: '温馨提示 🧡',
      icon: <ExclamationCircleOutlined rev={undefined} />,
      content: '是否确认退出登录？',
      okText: '确认',
      cancelText: '取消',
      onOk: handleSignOut
    })
  }

  // Dropdown Menu
  const items: MenuProps['items'] = [
    {
      key: '4',
      label: <span className='dropdown-item'>退出登录</span>,
      onClick: logout
    }
  ]

  return (
    <div style={{ padding: '7px 16px', display: 'flex' }}>
      <Dropdown menu={{ items }} arrow>
        <div style={{ display: 'flex' }}>
          <div
            style={{
              width: 42,
              height: 42,
              background: '#1677ff',
              borderRadius: '50%',
              lineHeight: '42px',
              textAlign: 'center',
              overflow: 'hidden'
            }}
          >
            {useInfo?.email?.split('@')[0]}
          </div>
          <div style={{ marginLeft: 8, display: 'flex', flexDirection: 'column' }}>
            <div className='username' style={{ paddingBottom: 6 }}>
              {useInfo?.email}
            </div>
            <span className='shenfen'>{access?.includes('administrator') ? '超级管理员' : '管理员'}</span>
          </div>
        </div>
      </Dropdown>
    </div>
  )
}

export default AvatarIcon
