import { RouteObject } from '@/router/router-config'
import Cookies from 'js-cookie'

const TokenKey = 'Token'

export function getToken() {
  return Cookies.get(TokenKey)
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function setToken(token: any) {
  return Cookies.set(TokenKey, token)
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}

/**
 * @description 递归查询对应的路由
 * @param {String} path 当前访问地址
 * @param {Array} routes 路由列表
 * @returns array
 */
export const searchRoute = (path: string, routes: RouteObject[] = []): RouteObject => {
  let result: RouteObject = {}
  for (const item of routes) {
    if (item.path === '/' && path === '/') return item.children[0]
    if ('/' + item.path === path) return item
    if (item.path === path) return item
    if (item.children) {
      const arr = path.split(item.path)
      path = arr[arr.length - 1]
      const res = searchRoute(path, item.children)
      if (Object.keys(res).length) result = res
    }
  }

  return result
}
