import WrapRoute from './router'
import './App.less'
import zhCN from 'antd/lib/locale/zh_CN'
import { ConfigProvider } from 'antd'
import { ConfigProvider as MobileConfigProvider } from 'antd-mobile'
import enUS from 'antd-mobile/es/locales/en-US'
import dayjs from 'dayjs'
import 'dayjs/locale/zh-cn'

dayjs.locale('zh-cn')

function App() {
  return (
    <ConfigProvider locale={zhCN}>
      <MobileConfigProvider locale={enUS}>
        <div className='app'>
          <WrapRoute />
        </div>
      </MobileConfigProvider>
    </ConfigProvider>
  )
}

export default App
