import React from 'react'
import { Result } from 'antd'
import loginLeft from '@/assets/images/login_left.png'
import style from './index.module.less'

const App: React.FC = () => {
  return (
    <div
      style={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', background: '#fff' }}
    >
      <Result
        icon={<img src={loginLeft} alt='login' width={600} />}
        title={<span className={style.title}>Welcome to the Admin Panel!</span>}
      />
    </div>
  )
}

export default App
